
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Button from '../../../atomic/atoms/Button/Button.vue';
import Loader from '../../../atomic/atoms/Loader/Loader.vue';
import Tooltip from '../../../atomic/atoms/Tooltip/Tooltip.vue';
import Modal from '../../../atomic/atoms/Modal/Modal.vue';
import Divider from '../../../atomic/atoms/Divider/Divider.vue';
import { FormBuilder, FormControl } from '@/utilities/Forms';
import { Validators } from '@/utilities/Validators/Validators';
import Input from '@/atomic/atoms/Input/Input.vue';

export default defineComponent({
  components: {
    'arc-input': Input,
    'arc-button': Button,
    'arc-loader': Loader,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-divider': Divider,
  },
  async mounted() {
    // this.response = await this.fetchMarketingOpportunityReview({
    //   emailId: this.$route.query.emailId,
    //   opportunityId: this.$route.query.opportunityId,
    // });
    this.emailId = this.$route.query.emailId as string;
    this.opportunityId = this.$route.query.opportunityId as string;
    this.setForm();
    this.loading = false;
  },
  watch: {
    // marketingCampaignReview: {
    //   deep: true,
    //   handler() {
    //
    //   },
    // },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },
  computed: {
    ...mapGetters(['modalData', 'marketingCampaignReview']),
  },
  data(): {
    formData: any;
    changesMade: boolean;
    loading: boolean;
    saving: boolean;
    response: any;
    campaign: any;
    emailId: string;
    opportunityId: string;
  } {
    return {
      formData: null,
      changesMade: false,
      loading: true,
      saving: false,
      response: null,
      campaign: null,
      emailId: '',
      opportunityId: '',
    };
  },

  methods: {
    ...mapActions(['fetchMarketingOpportunityReview', 'setMarketingOpportunityMallId']),
    setForm(): void {
      this.formData = new FormBuilder({
        mallId: new FormControl('', [Validators.required]),
      });
    },
    setChangesMade() {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    async save(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          emailId: this.emailId,
          opportunityId: this.opportunityId,
          mallId: this.formData?.controls?.mallId?.value,
        };

        this.saving = true;

        try {
          await this.setMarketingOpportunityMallId(payload);
          this.$notify({
            title: 'SUCCESS',
            text: 'Mall id saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
        } catch (err) {
          const error: any = err;
          this.$notify({
            title: 'ERROR',
            text: 'Unable to update Mall Id',
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Mall Id form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
  },
});
